import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/Layouts/Layout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BlogCard = makeShortcode("BlogCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <BlogCard mdxType="BlogCard">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{`We've been using hooks at work since a few days after they were released and generally they're great. I think though that, while the syntax is much simpler than for class based components, they do have some gotchas and there are some complexities that it is easy to miss when you first start using them.`}</p>
      <p>{`One of the things we've probably spent most time discussing 'when should we use this' has been the useCallback hook because the React docs are pretty sparse on this, so this post summarises what I have found are the use cases for this hook.`}</p>
      <h2>{`What does useCallback do?`}</h2>
      <p>{`Say we have the following react component:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const MyComponent = () => {
  const [someState, setSomeState] = useState(1);

  const handleClick = () => {
    console.log(someState);
  };

  return <button onClick={handleClick}>Click!</button>;
};
`}</code></pre>
      <p>{`Every time MyComponent is rendered (i.e. every time the function MyComponent is run), the handleClick function within it is recreated and so is assigned to a new location in memory. So, while the value handleClick remains unchanged, its reference doesn't.`}</p>
      <p>{`What useCallback does is preserve the reference for the function across renders of the component it belongs to.`}</p>
      <p>{`The code above rewritten to use useCallback looks like this:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const MyComponent = () => {
  const [someState, setSomeState] = useState(1);

  const handleClick = useCallback(() => {
    console.log(someState);
  }, [someState]);

  return <button onClick={handleClick}>Click!</button>;
};
`}</code></pre>
      <p>{`You can see that useCallback takes two arguments: the function definition and a dependency array. The array should contain every variable (including other functions) that the callback references. If one of these changes then the function will be recreated and it will have a new reference. So, you're not guarenteed to always and forever have the same reference but it can in many cases greatly reduce the number of times you will lose referntial integrity of the function between renders.`}</p>
      <p>{`And why the dependency array? Hooks rely on closures, so every render has its own functions and variables that are captured. So if the useCallback function depends on any of these variables it will need to be updated if any of them changes (or else you'll be in danger of using values from a stale closure).`}</p>
      <h2>{`Why would I need this?`}</h2>
      <p>{`The main takeaway from the section above is that useCallback helps preserve referential integrity...so you use it when you need refernetial integrity across renders.`}</p>
      <p>{`The most clear cut use case for this is when your function is used inside a useEffect (i.e. is a dependency of the effect). In this case, if you didn't wrap the function in useCallback then it would be recreated on every render and the effect would fire after every render because the functions did not have referential equality. If you use the es-lint react hooks package, which you definitely should, you'll be warned when this is the case.`}</p>
      <p>{`Another possible use case is when passing callback down to a child component via props. If the callback function retains referential integrity it won't trigger a re-render of the children it is passed to when the component that it is declared in re-renders. While preventing 'unecessary' re-renders sounds great, re-rendering is kind of what React is designed to do so it can be pretty cheap. So, this is where we get into a bit of grey area: we need to consider that using useCallback has an overhead and because of that using it may not be more efficient than just allowing React to do its thing.`}</p>
      <h2>{`How does it work (aka why you shouldn't use it all the time)?`}</h2>
      <p>{`When you use useCallback your code has to do three things:`}</p>
      <ol>
        <li parentName="ol">{`call useCallback (and run whatever logic that contains)`}</li>
        <li parentName="ol">{`create the callback function that you are defining`}</li>
        <li parentName="ol">{`create the array of dependencies`}</li>
      </ol>
      <p>{`And its not just on the first render that there's an overhead. On every subsequent render useCallback is called again and React needs to check whether to retain the reference to the old function based on whether the values in the dependency array have changed.`}</p>
      <p>{`(Note I am no expert at React internals but the above is a rough description of what happens).`}</p>
      <p>{`Where does this leave us then in terms of the question 'so, do I use useCallback when I am passing a function down in props'? Like so many things in programming, it isn't clear cut. I've found that there have been a small number of cases where using useCallback for a function prop has made a visible difference to the application or where the re-render has caused some other noticable and unwanted effect that I wanted to prevent. The rest of the time I now tend to not use useCallback. There are tools available (in React Dev tools) that allow you to profile the performance of your app and I would suggest that profiling to identify problem components is the first step in identifying where you might get some performance benefit from using useCallback.`}</p>
      <h2>{`tldr`}</h2>
      <p>{`useCallback gives you referential integrity. The react hooks eslint package will alert you to some clear uses for it but don't over-use it because it will negatively impact your apps performance.`}</p>
    </BlogCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      